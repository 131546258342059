import { useCardColumnCount } from "src/businesslogics/catalog/getCardColumnCount";
import { RecommendCards } from "src/components/molecules/home/v4/RecommendCards/RecommendCards";
import { ProductType } from "src/models/catalog/business/catalogBusiness";
import {
  CourseCardType,
  CourseRecommendType,
} from "src/models/home/homeV4.models";
import { useGetBusinessProducts } from "src/queries/catalog/businessCatalog.query";
import { useCustomRemoteConfig } from "../../../../../businesslogics/_common/hackle/useCustomRemoteConfig";

// 아카데미아 강의 추천 카드
export const ProductRecommend = () => {
  const remoteConfig: CourseRecommendType = useCustomRemoteConfig(
    "scc_main_recommend_course_cards",
    "json"
  );

  const cardColumnCount = useCardColumnCount();

  const productCardIds = [1, 2, 3, 4];
  const { data: productsData, isLoading } = useGetBusinessProducts(
    "scc",
    "all",
    "latest"
  );
  if (isLoading) return null;

  const filteredProducts = productsData?.filter((product) =>
    productCardIds.includes(product.id)
  );
  const courseCards = makeCourseCardType(filteredProducts);
  return (
    <RecommendCards
      title={remoteConfig.title}
      description={remoteConfig.description}
      cards={courseCards}
      columnCount={cardColumnCount}
    />
  );
};

const makeCourseCardType = (products: ProductType[]): CourseCardType[] => {
  return products.map((product) => ({
    courseId: product.id.toString(),
    keyword: product.thumbnailUrl,
    title: product.name,
    description: product.description,
    price: product.salePrice.toString(),
    url: product.detailPageUrl,
  }));
};
