import * as S from "./Gnb.mobile.style";
import React from "react";
import {
  useClickAnchor,
  useCustomGnb,
  useFixedPosition,
} from "../../../../businesslogics/_layout/gnb/v2";
import {
  CloseBtnSVG,
  HamburgerSVG,
} from "../../../atoms/_layout/gnb/v2/Gnb.style.svg";
import { GnbCategories } from "../molecules/GnbCategories";
import { GnbMyPage } from "../molecules/GnbMyPage";
import {
  isMyPageOpenAtom,
  isNavigatorOpenAtom,
} from "../../../../stores/_layout/gnb";
import { useAtom } from "jotai";
import { GnbMyPageLogo } from "../../../atoms/_layout/gnb/v2/GnbMyPageLogo";
import { LineBanner } from "../../../organisms/_layout/LineBanner/LineBanner";
import { sccAnchor } from "../../../../models/_layout/gnbV2.models";
import { LoginButton } from "../molecules/LoginButton";
import { RemoteSpartaLogo } from "src/components/atoms/Icons/SpartaLogo/RemoteSpartaLogo";
import { useIsLoggedInV2 } from "../../../../businesslogics/_common/login";
import { SearchLine } from "@teamsparta/design-system";
import { useRouter } from "next/router";
import { LogClick } from "src/components/atoms/_common/Logging/LogClick";

export const GnbMobileV3 = () => {
  const [isNavigatorOpen, setIsNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [isMyPageOpen, setIsMyPageOpen] = useAtom(isMyPageOpenAtom);
  const isFixed = useFixedPosition();
  const isLogin = useIsLoggedInV2();
  const router = useRouter();
  const onClickAnchor = useClickAnchor();
  const customGnb = useCustomGnb();

  const handleSearchClick = () => {
    router.push("/catalog/scc/search");
  };

  return (
    <S.Wrapper isTabOpened={isNavigatorOpen || isMyPageOpen} fixed={isFixed}>
      <S.TopMenuBar>
        {/* left side of GNB */}
        {customGnb?.left ? (
          <S.BtnWrapper onClick={() => customGnb.left.onClick()}>
            {customGnb.left.button}
          </S.BtnWrapper>
        ) : (
          <S.LeftBtnWrapper>
            {isNavigatorOpen ? (
              <CloseBtnSVG />
            ) : (
              <>
                <S.HamburgerSVGWrapper
                  onClick={() => {
                    setIsMyPageOpen(false);
                    setIsNavigatorOpen(!isNavigatorOpen);
                  }}
                >
                  <HamburgerSVG />
                </S.HamburgerSVGWrapper>
                {router.pathname.includes("/catalog") && (
                  <LogClick
                    logName="scc_catalogPage_search_click"
                    data={{ business: "scc" }}
                  >
                    <S.SearchLineWrapper onClick={handleSearchClick}>
                      <SearchLine size={20} color={"#3A3E41"} />
                    </S.SearchLineWrapper>
                  </LogClick>
                )}
              </>
            )}
          </S.LeftBtnWrapper>
        )}
        {/* center of GNB */}
        {customGnb?.pageName ? (
          <S.PageName>{customGnb.pageName}</S.PageName>
        ) : (
          <S.LogoWrapper onClick={() => onClickAnchor(sccAnchor)}>
            <RemoteSpartaLogo />
          </S.LogoWrapper>
        )}
        <S.GnbRightContainer>
          {/* right side of GNB */}
          {isLogin ? (
            customGnb?.right ? (
              <S.RightBtnWrapper onClick={() => customGnb.right.onClick()}>
                {customGnb.right.button}
              </S.RightBtnWrapper>
            ) : (
              <S.RightBtnWrapper>
                <GnbMyPageLogo />
              </S.RightBtnWrapper>
            )
          ) : (
            <LoginButton />
          )}
        </S.GnbRightContainer>
      </S.TopMenuBar>
      <LineBanner />
      <GnbCategories /> {/* left SNB */}
      <GnbMyPage /> {/* right SNB */}
    </S.Wrapper>
  );
};
