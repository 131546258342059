import instance from "axios";
import { useQuery } from "react-query";
import { useUserId } from "../../businesslogics/_common/auth";
import PrivateAxios from "../_common/privateInstance/privateInstance";
import { userMetaDataModel } from "../../models/_layout/gnbV2.models";

export type UserMetaData = {
  is_enrolled_exist: boolean;
  point_amount: number;
  active_coupon_count: number;
  active_voucher_count: number;
};

export type UserMetaDataModel = {
  classroom: string;
  point: string;
  coupon: string;
  voucher: string;
};

export interface UserMetaDto {
  ok: boolean;
  context: UserMetaData;
}

export const getUserMetaData = async (userId: string) => {
  try {
    const response = await instance.get<UserMetaDto>(
      `${process.env.ONLINE_API_URL}/v2/users/meta?user_id=${userId}`,
    );
    return new userMetaDataModel(response.data.context);
  } catch (e) {
    console.log(e);
  }
};

export const getUserV2CouponCount = async () => {
  try {
    return await PrivateAxios<number>(
      "get",
      `/user-info/available-coupons/count`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const useUserMetaQuery = () => {
  const userId = useUserId();
  return useQuery<UserMetaDataModel | undefined, Error>(
    ["userMeta", userId],
    () => {
      if (userId) {
        return getUserMetaData(userId);
      }
    },
  );
};

// 표최자 시스템에서 유효한 쿠폰 개수를 가져오는 쿼리
export const useUserCouponCountQuery = () => {
  const userId = useUserId();
  return useQuery<number | undefined, Error>(
    ["userV2CouponCount", userId],
    getUserV2CouponCount,
  );
};
