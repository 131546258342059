import axios from "axios";
import { useQuery } from "react-query";
import {
  GetCategoryResDto,
  GetMainCategorySlugResDto,
  ProductType,
  SortOption,
} from "src/models/catalog/business/catalogBusiness";

export const businessCatalogKey = {
  all: ["businessCatalog"] as const,
  mainCategory: (businessSlug: string) =>
    [...businessCatalogKey.all, businessSlug] as const,
  subCategories: (businessSlug: string, categorySlug: string) =>
    [...businessCatalogKey.all, businessSlug, categorySlug] as const,
  products: (
    businessSlug: string,
    categorySlug: string,
    sortOption: SortOption,
  ) =>
    [
      ...businessCatalogKey.all,
      businessSlug,
      categorySlug,
      sortOption,
    ] as const,
  mainCategorySlug: (businessSlug: string, categorySlug: string) =>
    [
      ...businessCatalogKey.all,
      businessSlug,
      categorySlug,
      "mainCategorySlug",
    ] as const,
};

export const getBusinessMainCategories = async (businessSlug: string) => {
  const response = await axios.get(
    `${process.env.ONLINE_API_V3_URL}/businesses/${businessSlug}/main-category`,
  );
  return response.data;
};

export const useGetBusinessMainCategories = (businessSlug: string) => {
  return useQuery<GetCategoryResDto>({
    queryKey: businessCatalogKey.mainCategory(businessSlug),
    queryFn: () => {
      return getBusinessMainCategories(businessSlug);
    },
  });
};

export const getBusinessSubCategories = async (
  businessSlug: string,
  categorySlug: string,
) => {
  const response = await axios.get(
    `${process.env.ONLINE_API_V3_URL}/businesses/${businessSlug}/categories/${categorySlug}/sub-categories`,
  );
  return response.data;
};

export const useGetBusinessSubCategories = (
  businessSlug: string,
  categorySlug: string,
) => {
  return useQuery<GetCategoryResDto>({
    queryKey: businessCatalogKey.subCategories(businessSlug, categorySlug),
    queryFn: () => {
      return getBusinessSubCategories(businessSlug, categorySlug);
    },
    enabled: !!businessSlug && !!categorySlug && categorySlug !== "all",
  });
};

export const getBusinessProducts = async (
  businessSlug: string,
  categorySlug: string,
  sortOption: SortOption,
): Promise<ProductType[]> => {
  const response = await axios.get(
    `${process.env.ONLINE_API_V3_URL}/businesses/${businessSlug}/categories/${categorySlug}/products?sort=${sortOption}`,
  );
  return response.data.filter((product) => !!product.detailPageUrl);
};

export const useGetBusinessProducts = (
  businessSlug: string,
  categorySlug: string,
  sortOption: SortOption,
) => {
  return useQuery<ProductType[]>({
    queryKey: businessCatalogKey.products(
      businessSlug,
      categorySlug,
      sortOption,
    ),
    queryFn: () => getBusinessProducts(businessSlug, categorySlug, sortOption),
    keepPreviousData: true,
  });
};

const getMainCategorySlug = async (
  businessSlug: string,
  categorySlug: string,
): Promise<GetMainCategorySlugResDto> => {
  const response = await axios.get<GetMainCategorySlugResDto>(
    `${process.env.ONLINE_API_V3_URL}/businesses/${businessSlug}/categories/${categorySlug}/parent`,
  );
  return response.data;
};

export const useGetMainCategorySlug = (
  businessSlug: string,
  categorySlug: string,
) => {
  return useQuery<GetMainCategorySlugResDto>({
    queryKey: businessCatalogKey.mainCategorySlug(businessSlug, categorySlug),
    queryFn: () => getMainCategorySlug(businessSlug, categorySlug),
  });
};
