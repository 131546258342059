// components/RouteGuard.tsx
"use client";

import { usePathname } from "next/navigation";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { academiaFFAtom } from "./flag.store";
import { IN_HOUSE_URL } from "../../models/_common/urls";

const AGE = ["BP", "AP"] as const;
type Age = (typeof AGE)[number];

export function AcademiaFFRouteGuard() {
  const pathname = usePathname();
  const { isLoading, isOn } = useAtomValue(academiaFFAtom);

  const pageFrom = useMemo<Age | undefined>(() => {
    if (!pathname) return undefined;

    if (pathname.startsWith("/online/") || pathname === "/catalog") {
      console.debug("BP");
      return "BP";
    }
    if (pathname.startsWith("/product/") || pathname === "/catalog/scc") {
      console.debug("AP");
      return "AP";
    }
    return undefined;
  }, [pathname]);

  useEffect(() => {
    if (isLoading || !pageFrom) return;

    if (isOn && pageFrom === "BP") {
      window.location.replace(IN_HOUSE_URL.상품탐색);
    }
    if (!isOn && pageFrom === "AP") {
      window.location.replace(IN_HOUSE_URL.수업탐색);
    }
  }, [pageFrom, isLoading, isOn]);

  return null;
}
